import { useMutation } from 'react-query'
import { postTipoOrcamentoPrevistoRealizadoApi } from '../../domain/dashboard/api'

export const usePostTipoOrcamentoPrevistoRealizado = () => {
  const {
    mutateAsync: postTipoOrcamentoPrevistoRealizadoAsync,
    isLoading: isLoadingPostTipoOrcamentoPrevistoRealizado,
    isError: isErrorPostTipoOrcamentoPrevistoRealizado,
    error: errorPostTipoOrcamentoPrevistoRealizado,
    mutate: postTipoOrcamentoPrevistoRealizado,
    data
  } = useMutation(postTipoOrcamentoPrevistoRealizadoApi, {
    retry: false
  })

  const orcamentoPrevistoRealizado = data?.results
  const orcamentoCategorias = []

  if (Array.isArray(orcamentoPrevistoRealizado)) {
    orcamentoPrevistoRealizado?.forEach((item) => {
      // @ts-expect-error ts(2349)
      orcamentoCategorias.push({
        nome: item.nome,
        percentual: item.estimado > 0 ? (item.totalMedio / item.estimado) * 100 : 0,
        estimado: item.estimado,
        realizado: item.totalMedio,
        tipoOrcamento: item.tipoOrcamento
      })
    })
  }

  return {
    postTipoOrcamentoPrevistoRealizado,
    postTipoOrcamentoPrevistoRealizadoAsync,
    isLoadingPostTipoOrcamentoPrevistoRealizado,
    isErrorPostTipoOrcamentoPrevistoRealizado,
    errorPostTipoOrcamentoPrevistoRealizado,
    orcamentoPrevistoRealizado,
    orcamentoCategorias
  }
}
