import { noticeError } from '../../../services/Monitoring'
import { postWithTokenApiV2 } from '../../../webClient'
import { Results } from '../../Results'

interface IPostOrcamentoPeriodo {
  familyId?: number
  dataInicial: string
  dataFinal: string
}

interface IOrcamentoPeriodo {
  orcamentoId: number
  categoriaId: number
  meioPagamentoPadraoId: number
  nomeOrcamento: string
  nomeCategoria: string
  nomeMeioPagamento: number
  grupo: number
  frequencia: number
  estimado: number
  total: number
  totalMedio: number
  media: number
  minimo: number
  maximo: number
  quantidade: number
  orcamentoSuspenso: number
  categoriaSuspenso: number
  tipoOrcamento: number
}

export const postOrcamentoPeriodoApi = async ({
  dataInicial,
  dataFinal,
  familyId
}: IPostOrcamentoPeriodo) => {
  if (!familyId) {
    console.log('postOrcamentoPeriodo: FamilyId is required')
    return
  }

  const url = `/api/dashboard/orcamento-no-periodo/${familyId}`
  const { data, status } = await postWithTokenApiV2<
    Results<IOrcamentoPeriodo[]>,
    Partial<IPostOrcamentoPeriodo & { suspenso: boolean }>
  >(url, {
    dataInicial,
    dataFinal,
    suspenso: false
  })

  if (status !== 200) {
    const msg = `Error posting Orcamento no Periodo, familiaId: ${familyId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}
