import React, { createContext, FunctionComponent, useEffect, useMemo } from 'react'
import { isJwtValid, parseJwt } from '../../../domain/auth'
import { useAuthStore } from '../../../store/auth'
import {
  ContextValue,
  PlannedContextValue,
  PlannerContextValue,
  Props,
  SupportContextValue,
  UnauthenticatedContextValue
} from './types'

const Context = createContext<ContextValue | undefined>(undefined)

const Provider: FunctionComponent<Props> = ({ children }) => {
  const { setIsAuthenticated, jwt, getToken, setJwt, reset } = useAuthStore()
  const { jwt: rawJwt } = getToken()

  const token = useMemo(() => {
    if (!rawJwt || !isJwtValid(rawJwt)) return undefined
    return parseJwt(rawJwt)
  }, [rawJwt])

  let contextValue: ContextValue

  useEffect(() => {
    if (rawJwt !== jwt)
      setIsAuthenticated({
        jwt: rawJwt ?? undefined,
        token
      })
  }, [rawJwt, token, setIsAuthenticated, jwt])

  useEffect(() => {
    setIsAuthenticated({
      jwt: rawJwt ?? undefined,
      token: token
    })
  }, [rawJwt, token, setIsAuthenticated])

  if (!token) {
    const complement: UnauthenticatedContextValue = {
      token,
      isPlanned: false,
      isPlanner: false,
      isSupport: false,
      isManager: false,
      authenticated: rawJwt ? undefined : false,
      setJwt,
      reset
    }
    contextValue = complement
  } else if (token.pessoaId && token.familiaId) {
    const complement: PlannedContextValue = {
      token,
      isPlanned: true,
      isPlanner: false,
      isSupport: false,
      isManager: false,
      authenticated: true,
      familyId: token.familiaId,
      pessoaId: token.pessoaId,
      setJwt,
      reset
    }
    contextValue = complement
  } else if (token.planejadorId && token.empresaId) {
    const complement: PlannerContextValue = {
      token,
      isPlanned: false,
      isPlanner: true,
      isManager: token.manager ?? false,
      isSupport: false,
      authenticated: true,
      plannerId: token.planejadorId,
      companyId: token.empresaId,
      setJwt,
      reset
    }
    contextValue = complement
  } else if (!token.pessoaId && !token.planejadorId && !token.familiaId) {
    const complement: SupportContextValue = {
      token,
      isPlanned: false,
      isPlanner: false,
      isManager: false,
      isSupport: true,
      authenticated: true,
      setJwt,
      reset
    }
    contextValue = complement
  } else throw new Error('Invalid token, unexpected combination of fields')

  return <Context.Provider value={{ ...contextValue, reset }}>{children}</Context.Provider>
}

export const useAuth = () => React.useContext<ContextValue | undefined>(Context)

export { Provider as AuthProvider, Context as AuthContext }

export type { Props as AuthProviderProps, ContextValue as AuthContextValue }
