import { noticeError } from '../../services/Monitoring'
import { deleteWithTokenApiV2, getWithTokenApiV2, postWithTokenApiV2 } from '../../webClient'

export interface IDiagnosticSummaryResponse {
  groupName: string
  options: { id: string; name: string }[]
}

export const getDiagnosticSummaryApi = async () => {
  const { data, status } = await getWithTokenApiV2('api/FinancialDiagnostic/')

  if (status !== 200) {
    const msg = 'Error getting Diagnostic summary'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

export const postCreateDiagnosticApi = async (body) => {
  const { data, status } = await postWithTokenApiV2(`api/FinancialDiagnostic/${body.FamilyId}`, body)

  if (status !== 200) {
    const msg = 'Error posting Create Diagnostic'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

interface IDiagnosticsByFamilyId {
  financialDiagnosticId: '08dd001a-2c14-4c7d-89e2-3963ed186438'
  name: 'Raio-X Financeiro 0001-01-01 até 0001-01-01'
  pdfUrl: null
}
export const getDiagnosticsByFamilyIdApi = async ({ familyId }: { familyId?: number }) => {
  if (!familyId) {
    console.error('Error getting Diagnostics by familyId: familyId is required')
    return
  }
  const { data, status } = await getWithTokenApiV2<IDiagnosticsByFamilyId[]>(
    `api/FinancialDiagnostic/${familyId}`
  )

  if (status !== 200) {
    const msg = 'Error getting Diagnostics by familyId'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}

// TODO: REFACTOR
export const deleteDiagnosticsByFamilyIdApi = async ({
  financialDiagnosticId
}: {
  financialDiagnosticId?: string
}) => {
  if (!financialDiagnosticId) {
    console.error('Error getting Diagnostics by financialDiagnosticId: financialDiagnosticId is required')
    return
  }
  const { data, status } = await deleteWithTokenApiV2(`api/FinancialDiagnostic/${financialDiagnosticId}`)

  if (status !== 200) {
    const msg = 'Error getting Diagnostics by familyId'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}
