import { create } from 'zustand'

const inicialState = {
  showDiagnostics: false,
  showCreateDiagnostic: false,
  refetchDiagnostics: false
}

export interface IDiagnosticStore {
  showDiagnostics: boolean
  showCreateDiagnostic: boolean
  refetchDiagnostics: boolean
  modalDiagnostics: (value: boolean) => void
  modalDiagnosticsRefecth: (value: boolean) => void
  modalCreateDiagnostic: (value: boolean) => void
}

export const useDiagnosticStore = create<IDiagnosticStore>((set) => ({
  ...inicialState,
  modalDiagnosticsRefecth: (value: boolean) => set({ refetchDiagnostics: value }),
  modalDiagnostics: (value: boolean) => set({ showDiagnostics: value }),
  modalCreateDiagnostic: (value: boolean) => set({ showCreateDiagnostic: value })
}))
