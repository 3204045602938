import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Row } from 'reactstrap'
import LoadingOverlay from 'react-loading-overlay-ts'
import routes from '../routes.tsx'
import '../assets/css/spinner-aux.css'

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add('bg-default')
  }
  componentWillUnmount() {
    document.body.classList.remove('bg-default')
  }
  getRoutes = (routes) => {
    if (routes == null) {
      return <></>
    }

    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return <Route path={`${prop.layout}${prop.path}`} component={prop.component} key={key} />
      } else {
        return null
      }
    })
  }
  render() {
    return (
      <>
        <LoadingOverlay active={global.spinnerActive} spinner text={global.spinnerMessage}></LoadingOverlay>
        <div
          className="auth"
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Row className="justify-content-center" style={{ height: '100vh', width: '100vw' }}>
            <Switch>{this.getRoutes(routes)}</Switch>
          </Row>
        </div>
      </>
    )
  }
}

export default Auth
