import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import AdminLayout from './layouts/Admin'
import AuthLayout from './layouts/Auth'
import { LoadingBackground } from './components/Loading'
import { AuthProvider, CoreContextProvider } from './hooks/contexts'
import { PublicRoute } from './components/AuthRoute/publicRoute'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { PrivateRoute } from './components/AuthRoute/privateRoute'
import { CookiesProvider } from 'react-cookie'
import domains from './theme/relationsThemeDomains.json'
import './global'
import './assets/css/global.css'
import './assets/vendor/nucleo/css/nucleo.css'
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css'
import './assets/css/argon-dashboard-react.css'
import 'remixicon/fonts/remixicon.css'

const cacheTime = 30 * 1000
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime,
      retry: 2,
      retryDelay: 2000,
      refetchOnWindowFocus: false
    }
  }
})
const localStoragePersistor = createWebStoragePersistor({
  storage: window.localStorage,
  key: 'REACT_QUERY_OFFLINE_CACHE'
})
persistQueryClient({
  queryClient,
  persistor: localStoragePersistor,
  maxAge: cacheTime
})

const reactDevToolsIsEnabled = process.env.REACT_APP_DEV_TOOLS === 'true'
if (!reactDevToolsIsEnabled) {
  disableReactDevTools()
}

const { hostname } = window.location
const filteredDomains = Object.entries({
  ...domains,
  'app.meuvista.com': 'meuvista'
})
  .map((item) => !item.includes('.qa.') && item[0])
  .filter((a) => a)
filteredDomains?.forEach((item) => {
  if (String(item).includes(hostname)) {
    console.log = () => null
  }
})

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || '6LfXA2MqAAAAAJbw6mrANH_aHs4Q67BHKBm8bt7a'

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <AuthProvider>
          <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <CoreContextProvider>
              <BrowserRouter basename="/v1">
                <Suspense fallback={<LoadingBackground />}>
                  <Switch>
                    <Route
                      path="/admin"
                      render={(props) => (
                        <PrivateRoute>
                          <AdminLayout betaUser={false} {...props} />
                        </PrivateRoute>
                      )}
                    />

                    <Route
                      path="/auth"
                      render={(props) => (
                        <PublicRoute>
                          <AuthLayout {...props} />
                        </PublicRoute>
                      )}
                    />

                    <Route path="/" render={() => <Redirect to="/admin/home" />} />
                  </Switch>
                </Suspense>
              </BrowserRouter>
            </CoreContextProvider>
          </GoogleReCaptchaProvider>
        </AuthProvider>
      </CookiesProvider>
    </QueryClientProvider>
  )
}

const appContainer = document.getElementById('root')
if (appContainer == null) {
  console.error('No app container found')
} else {
  const root = createRoot(appContainer)
  root.render(<App />)
}
