import { noticeError } from '../../../services/Monitoring'
import { postWithToken } from '../../../webClient'
import { Results } from '../../Results'

export interface IOrcamentoPrevistoRealizado {
  classePaiId: number
  id: number
  nome: string
  orcamentoNome: string
  ano: number
  mes: number
  estimado: number
  total: number
  totalMedio: number
  media: number
  minimo: number
  maximo: number
  quantidade: number
  tipoOrcamento: string
}

export const postTipoOrcamentoPrevistoRealizadoApi = async ({
  familyId,
  filter
}: {
  familyId?: number
  filter: {
    DataInicial?: string
    DataFinal?: string
  }
}) => {
  if (!familyId) {
    console.log('fetchByFamily: familyId is required')
    return
  }
  const url = `/dashboard/tipo-orcamento-previsto-realizado/${familyId}`
  const { data, status } = await postWithToken<Results<IOrcamentoPrevistoRealizado[]>, unknown>(url, filter)

  if (status !== 200) {
    const msg = 'Error getting tipo orcamento previsto realizado'
    const error = new Error(msg)
    console.error(error)
    noticeError(error)
    throw new Error(msg)
  }

  return data
}
