import React from 'react'
import * as S from '../styles'

export const Title = ({
  title = 'Raio-X Financeiro',
  button = { onClick: () => {}, text: 'Criar Raio-X', disabled: false, loading: false, type: 'button' }
}: {
  title?: string
  withBorderRadius?: boolean
  button?: {
    onClick: () => void
    text: string
    disabled?: boolean
    loading?: boolean
    type?: 'button' | 'submit'
  }
}) => {
  return (
    <S.Header>
      <S.Title>{title}</S.Title>

      {/* Filters */}

      <S.ButtonCreate
        onClick={button?.onClick}
        text={button?.text}
        loading={button?.loading}
        invertedColors
      />
    </S.Header>
  )
}
