import React from 'react'
import * as S from './styles'

export const ConfirmOperation = ({ updateState, disabled, value }) => {
  const confirmOperationHandleChange = (value: boolean) => updateState(!value)

  if (typeof disabled !== 'number') {
    return <></>
  }

  const Label = (
    <>
      Considerar dentro de
      <strong>Gestão de Orçamentos</strong>
    </>
  )

  return <S.Checkbox label={Label} onChange={confirmOperationHandleChange} checked={value} />
}
