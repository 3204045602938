import { QueryOptions, useQuery } from 'react-query'
import {
  getAllCompanies,
  getFamilyBillingSummary,
  Params as GetAllCompaniesParams,
  Response as GetAllCompaniesResponse
} from '../../domain/company/api/getAllCompanies'

export const cacheBaseKey = 'companies'

type UseGetAllCompaniesParams = {
  options?: QueryOptions<GetAllCompaniesResponse, Error>
} & GetAllCompaniesParams

export const useGetAllCompaniesQuery = ({
  desc = true,
  options,
  orderBy,
  pageIndex,
  pageSize,
  search,
  filterDate
}: UseGetAllCompaniesParams) => {
  const {
    data: companies,
    isError: isCompaniesError,
    isLoading: isLoadingCompanies,
    error: companiesError,
    refetch: refetchGetAllCompanies
  } = useQuery(
    [cacheBaseKey, 'list', desc, orderBy, pageIndex, pageSize, search, filterDate],
    () => getAllCompanies({ desc, orderBy, pageIndex, pageSize, search, filterDate }),
    options
  )

  return {
    companies: companies || {
      companies: [],
      totalCompanies: 0,
      totalFamilies: 0,
      totalInvoice: 0,
      totalPlanners: 0
    },
    isCompaniesError,
    isLoadingCompanies,
    companiesError,
    refetchGetAllCompanies
  }
}

export const useFamilyBillingSummary = ({ filterDate }) => {
  const {
    data: familyBillingSummary,
    isError: isFamilyBillingSummaryError,
    isLoading: isLoadingFamilyBillingSummary,
    error: familyBillingSummaryError,
    refetch: refetchGetFamilyBillingSummary
  } = useQuery([cacheBaseKey, 'familyBillingSummary'], () => getFamilyBillingSummary({ filterDate }), {
    enabled: !!filterDate,
    refetchOnWindowFocus: false,
    retry: 3
  })

  return {
    familyBillingSummary,
    isFamilyBillingSummaryError,
    isLoadingFamilyBillingSummary,
    familyBillingSummaryError,
    refetchGetFamilyBillingSummary
  }
}
