export const defaultValues = {
  FamilyId: 0,
  FinancialDiagnosticOptionIds: [],
  BudgetObservation: '',
  PatrimonyObservation: '',
  StrongPointComment: '',
  ChallengeComment: '',
  PriorityComment: '',
  StartDate: '',
  EndDate: '',
  Goals: [],
  Budgets: [],
  IncomeNotes: '',
  ExpenseNotes: '',
  Expenses: [],
  FinancialAsset: undefined,
  FixedAsset: undefined,
  Debts: undefined,
  Asset: undefined,
  BudgetAnalysis: ''
}
