import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as S from './styles'
import { useDiagnosticStore } from '../../../../store/Diagnostic'
import { Title } from '../Title'
import { Checkbox, TextareaAutosize } from '@material-ui/core'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useGetPlansDreamsByFamilyId } from '../../../../queries/plansDreams'
import { useCoreContext } from '../../../../hooks/contexts/coreProvider'
import { DreamCardList } from './DreamCard'
import { useGetDiagnosticSummary, usePostCreateDiagnostic } from '../../../../queries/diagnostics/queries'
import { IFormCreateDiagnostic } from './types'
import { usePostTipoOrcamentoPrevistoRealizado } from '../../../../queries/dashboard'
import { useLocalStorage } from '../../../../hooks/useLocalStorage'
import { endOfMonth, getMonth, getYear, startOfMonth } from 'date-fns'
import { formatCurrencyBRL } from '../../../Utils'
import { useGetAllEquitys } from '../../../../queries/investments/queries'
import { defaultValues } from './constants'
import { usePostOrcamentoPeriodo } from '../../../../queries/orcamento/mutations'
import { showAlert } from '../../../../hooks'
import { noticeError } from '../../../../services/Monitoring'

export const CreateDiagnostic = () => {
  const { familiaId: familyId } = useCoreContext()
  const { showCreateDiagnostic, modalCreateDiagnostic, modalDiagnosticsRefecth } = useDiagnosticStore()
  const { plansDreams, getPlansDreamsByFamilyId } = useGetPlansDreamsByFamilyId()
  const {
    postCreateDiagnosticAsync,
    isLoadingPostCreateDiagnostic,
    isErrorPostCreateDiagnostic,
    errorPostCreateDiagnostic,
    postCreateDiagnosticSuccess,
    resetPostCreateDiagnostic
  } = usePostCreateDiagnostic()
  const { postTipoOrcamentoPrevistoRealizadoAsync, orcamentoPrevistoRealizado } =
    usePostTipoOrcamentoPrevistoRealizado()
  const { postOrcamentoPeriodoAsync, postingOrcamentoPeriodoData } = usePostOrcamentoPeriodo()
  const { xRaySummary } = useGetDiagnosticSummary()
  const pontosFortes = xRaySummary?.find((item) => item.groupName === 'Pontos Fortes')
  const desafios = xRaySummary?.find((item) => item.groupName === 'Desafios')
  const prioridades = xRaySummary?.find((item) => item.groupName.trim() === 'Prioridades')
  const { value: DataInicial } = useLocalStorage('dataInicio')
  const { value: DataFinal } = useLocalStorage('dataFim')
  const [FinancialDiagnosticOptionIds, setFinancialDiagnosticOptionIds] = useState<string[]>([])
  const filteredOrcamentoPeriodo = postingOrcamentoPeriodoData
    ?.filter((item) => item.tipoOrcamento === 3)
    .filter((item) => item.total && item.totalMedio && item.estimado)
    .sort((a, b) => b.total - a.total)
    .reverse()
  const { allEquitys } = useGetAllEquitys({
    familyId,
    params: {
      startDate: DataInicial,
      endDate: DataFinal
    }
  })
  const { fixedAsset, investments } = allEquitys || {}
  const orcamentoDespesasReceitas = useMemo(
    () => orcamentoPrevistoRealizado?.filter((item) => item.nome === 'Despesas' || item.nome === 'Receitas'),
    [orcamentoPrevistoRealizado]
  )
  const orcamentoDespesas = useMemo(
    () => orcamentoPrevistoRealizado?.find((item) => item.nome === 'Despesas'),
    [orcamentoPrevistoRealizado]
  )
  const orcamentoReceitas = useMemo(
    () => orcamentoPrevistoRealizado?.find((item) => item.nome === 'Receitas'),
    [orcamentoPrevistoRealizado]
  )
  const orcamentoDividas = useMemo(
    () => orcamentoPrevistoRealizado?.find((item) => item.nome === 'Dívidas'),
    [orcamentoPrevistoRealizado]
  )
  const orcamentoInvestimentos = useMemo(
    () => orcamentoPrevistoRealizado?.find((item) => item.nome === 'Investimentos'),
    [orcamentoPrevistoRealizado]
  )
  const investmentsReal = investments?.reduce((total, item) => total + item.totalAmount, 0) || 0
  const fixedAssetTotal = fixedAsset?.reduce((total, item) => total + item.value, 0) || 0
  const orcamentoDebtsRealTotal = orcamentoDividas?.total || 0

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if (event.target.checked) {
      setFinancialDiagnosticOptionIds([...FinancialDiagnosticOptionIds, id])
    } else {
      setFinancialDiagnosticOptionIds(FinancialDiagnosticOptionIds?.filter((id) => id !== event.target.value))
    }
  }

  const submitButtonRef = useRef<HTMLButtonElement>(null)
  const { register, handleSubmit } = useForm<IFormCreateDiagnostic>({
    defaultValues: {
      ...defaultValues,
      FamilyId: familyId || 0,
      StartDate: DataInicial || undefined,
      EndDate: DataFinal || undefined
    }
  })

  const onSubmit: SubmitHandler<IFormCreateDiagnostic> = (data) => {
    const Goals = plansDreams?.map((item) => ({
      AccumulatedValue: item.valorEstimadoPresente || 0,
      Dream: item.descricao || '',
      FutureValue: item.valorEstimadoFuturo || 0,
      Image: item.imagem || 'https://app.meuvista.com/v1/static/media/sonhos-padrao.dbde1829c8291a730660.jpg',
      Installment: item.prazo || 0,
      InstallmentValue: item.parcela || 0,
      Month: getMonth(new Date(item.dataFinal)),
      Year: getYear(new Date(item.dataFinal))
    }))

    const orcamentoReceitaEstimado = orcamentoReceitas?.estimado || 0
    const orcamentoReceitaReal = orcamentoReceitas?.total || 0
    const orcamentoDespesaEstimado = orcamentoDespesas?.estimado || 0
    const orcamentoDespesaReal = orcamentoDespesas?.total || 0
    const orcamentoDividaEstimado = orcamentoDividas?.estimado || 0
    const orcamentoDividaReal = orcamentoDividas?.total || 0
    const orcamentoInvestimentoEstimado = orcamentoInvestimentos?.estimado || 0
    const orcamentoInvestimentoReal = orcamentoInvestimentos?.total || 0

    const Budgets = [
      {
        Name: 'Ideal',
        Expense: orcamentoDespesaEstimado,
        Income: orcamentoReceitaEstimado,
        Debt: orcamentoDividaEstimado,
        Investment: orcamentoInvestimentoEstimado,
        total:
          orcamentoReceitaEstimado +
          orcamentoInvestimentoEstimado -
          orcamentoDespesaEstimado -
          orcamentoDividaEstimado
      },
      {
        Name: 'Real',
        Expense: orcamentoDespesaReal,
        Income: orcamentoReceitaReal,
        Debt: orcamentoDividaReal,
        Investment: orcamentoInvestimentoReal,
        total: orcamentoReceitaReal + orcamentoInvestimentoReal - orcamentoDespesaReal - orcamentoDividaReal
      }
    ]

    const FamilyAssets = {
      TotalDebts: orcamentoDebtsRealTotal,
      TotalFinancialAssets: investmentsReal,
      TotalFixedAssets: fixedAssetTotal,
      TotalNetAssets: investmentsReal + fixedAssetTotal - orcamentoDebtsRealTotal
    }

    const Expenses = {
      ActualValue: filteredOrcamentoPeriodo?.reduce((total, item) => total + item.total, 0) || 0,
      Categories: filteredOrcamentoPeriodo?.map((item) => ({
        Name: item.nomeCategoria,
        Percentage: 0.5,
        ActualValue: item.total,
        IdealValue: item.estimado
      }))
    }

    const newData = {
      ...data,
      Budgets,
      FinancialAsset: {
        value: investmentsReal
      },
      FixedAsset: {
        value: fixedAssetTotal
      },
      Debts: {
        value: orcamentoDebtsRealTotal || 0
      },
      FamilyAssets,
      Expenses,
      FinancialDiagnosticOptionIds,
      Goals
    }

    postCreateDiagnosticAsync(newData)
  }

  const filter = useMemo(
    () => ({
      DataFinal: DataFinal || endOfMonth(new Date()).toISOString(),
      DataInicial: DataInicial || startOfMonth(new Date()).toISOString()
    }),
    [DataFinal, DataInicial]
  )

  useEffect(() => {
    if (showCreateDiagnostic) {
      postTipoOrcamentoPrevistoRealizadoAsync({
        familyId: familyId || undefined,
        filter
      })
      postOrcamentoPeriodoAsync({
        dataFinal: DataFinal || endOfMonth(new Date()).toISOString(),
        dataInicial: DataInicial || startOfMonth(new Date()).toISOString(),
        familyId: familyId || undefined
      })
    }
  }, [
    DataFinal,
    DataInicial,
    familyId,
    filter,
    postOrcamentoPeriodoAsync,
    postTipoOrcamentoPrevistoRealizadoAsync,
    showCreateDiagnostic
  ])

  useEffect(() => {
    if (showCreateDiagnostic) {
      if (familyId) getPlansDreamsByFamilyId({ familyId: familyId })
    }
  }, [familyId, getPlansDreamsByFamilyId, showCreateDiagnostic])

  useEffect(() => {
    if (isErrorPostCreateDiagnostic) {
      const errorMessage = String(errorPostCreateDiagnostic || 'Erro ao criar diagnostico')
      noticeError(new Error(errorMessage))
      showAlert('Erro', errorMessage, () => resetPostCreateDiagnostic())
    }
  }, [errorPostCreateDiagnostic, isErrorPostCreateDiagnostic, resetPostCreateDiagnostic])

  useEffect(() => {
    if (postCreateDiagnosticSuccess) {
      modalCreateDiagnostic(false)
      showAlert('Diagnóstico criado com sucesso', '', () => {
        modalDiagnosticsRefecth(true)
        resetPostCreateDiagnostic()
      })
    }
  }, [modalCreateDiagnostic, modalDiagnosticsRefecth, postCreateDiagnosticSuccess, resetPostCreateDiagnostic])

  // console.log({
  //   xRaySummary,
  //   orcamentoCategorias,
  //   plansDreams
  // })

  console.log({ DataInicial, DataFinal })

  return (
    <S.ModalV2
      title={
        <Title
          button={{
            text: 'Salvar e Exportar',
            onClick: () => submitButtonRef?.current?.click(),
            type: 'submit',
            loading: isLoadingPostCreateDiagnostic
          }}
        />
      }
      className="modal-diagnostico"
      open={showCreateDiagnostic}
      close={() => modalCreateDiagnostic(false)}
    >
      <S.Content>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          <S.FormSection>
            <S.FormTitle>Sonhos da família:</S.FormTitle>

            <DreamCardList dreams={plansDreams} />
          </S.FormSection>

          <S.FormSection>
            <S.FormTitle>Orçamento:</S.FormTitle>

            {/* Tabela Receita x Despesa */}
            <S.Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Real</th>
                  <th>Ideal</th>
                </tr>
              </thead>
              <tbody>
                {orcamentoDespesasReceitas?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.nome}</td>
                    <td>{formatCurrencyBRL(item.total || 0)}</td>
                    <td>{formatCurrencyBRL(item.estimado || 0)}</td>
                  </tr>
                ))}
              </tbody>
            </S.Table>

            <S.FormTitle>Comentário:</S.FormTitle>
            <TextareaAutosize
              style={{ width: '100%' }}
              minRows={5}
              placeholder="Observações do planejador para o orçamento"
              {...register('BudgetObservation')}
            />
          </S.FormSection>

          <S.FormSection>
            <S.FormTitle>Patrimônio:</S.FormTitle>

            {/* Tabela Financeiro x Imobilizado x Dívidas x Patrimonio Líquido */}
            <S.Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Valor total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Financeiro</td>
                  <td>{formatCurrencyBRL(investmentsReal)}</td>
                </tr>
                <tr>
                  <td>Imobilizado</td>
                  <td>{formatCurrencyBRL(fixedAssetTotal)}</td>
                </tr>
                <tr>
                  <td>Dívidas</td>
                  <td>{formatCurrencyBRL(orcamentoDebtsRealTotal)}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Patrimonio Líquido</td>
                  <td>{formatCurrencyBRL(investmentsReal + fixedAssetTotal - orcamentoDebtsRealTotal)}</td>
                </tr>
              </tfoot>
            </S.Table>

            <S.FormTitle>Comentário:</S.FormTitle>
            <TextareaAutosize
              style={{ width: '100%' }}
              minRows={5}
              placeholder="Observações do planejador para o patrimônio"
              {...register('PatrimonyObservation')}
            />
          </S.FormSection>

          <S.FormSection>
            <S.FormTitle>Conclusão Geral</S.FormTitle>
            <S.FormSubtitle>{pontosFortes?.groupName}:</S.FormSubtitle>

            <S.FormGroup padding>
              {pontosFortes?.options?.map((item) => (
                <S.FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox onChange={(event) => handleChangeCheckbox(event, item.id)} name={item.name} />
                  }
                  label={item.name}
                />
              ))}
            </S.FormGroup>

            <S.FormSubtitle>Comentário:</S.FormSubtitle>
            <TextareaAutosize
              style={{ width: '100%' }}
              minRows={5}
              placeholder="Observações Conclusão Geral"
              {...register('StrongPointComment')}
            />
          </S.FormSection>

          <S.FormSection>
            <S.FormSubtitle>{desafios?.groupName}:</S.FormSubtitle>

            <S.FormGroup padding>
              {desafios?.options?.map((item) => (
                <S.FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox onChange={(event) => handleChangeCheckbox(event, item.id)} name={item.name} />
                  }
                  label={item.name}
                />
              ))}
            </S.FormGroup>

            <S.FormSubtitle>Comentário:</S.FormSubtitle>
            <TextareaAutosize
              style={{ width: '100%' }}
              minRows={5}
              placeholder="Observações do planejador para os desafios"
              {...register('ChallengeComment')}
            />
          </S.FormSection>

          <S.FormSection>
            <S.FormSubtitle>{prioridades?.groupName?.trim()}:</S.FormSubtitle>

            <S.FormGroup padding>
              {prioridades?.options?.map((item) => (
                <S.FormControlLabel
                  key={item.id}
                  control={
                    <Checkbox onChange={(event) => handleChangeCheckbox(event, item.id)} name={item.name} />
                  }
                  label={item.name}
                />
              ))}
            </S.FormGroup>

            <S.FormSubtitle>Comentário:</S.FormSubtitle>
            <TextareaAutosize
              style={{ width: '100%' }}
              minRows={5}
              placeholder="Observações do planejador para as prioridades"
              {...register('PriorityComment')}
            />
          </S.FormSection>

          <button
            ref={submitButtonRef}
            type="submit"
            disabled={isLoadingPostCreateDiagnostic}
            style={{ display: 'none' }}
          >
            Criar
          </button>
        </S.Form>
      </S.Content>
    </S.ModalV2>
  )
}
