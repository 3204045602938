import { useQuery, useMutation } from 'react-query'
import {
  getDreamImageByDreamIdApi,
  getPlansDreamsByFamilyIdApi,
  postCreateDreamApi
} from '../../domain/plansDreams/api'

export const useGetPlansDreamsByFamilyId = () => {
  const {
    data: plansDreams,
    isLoading: isLoadingPlansDreams,
    isError: isErrorPlansDreams,
    error: plansDreamsError,
    mutateAsync: getPlansDreamsByFamilyId
  } = useMutation(getPlansDreamsByFamilyIdApi)

  return { getPlansDreamsByFamilyId, plansDreams, isLoadingPlansDreams, isErrorPlansDreams, plansDreamsError }
}

export const useGetDreamImageByDreamId = ({ dreamId }) => {
  const {
    data: dreamImage,
    isLoading: isLoadingDreamImage,
    isError: isErrorDreamImage,
    error: dreamImageError
  } = useQuery<unknown, Error, string>(
    ['dreamImage', dreamId],
    () => getDreamImageByDreamIdApi({ dreamId }),
    { enabled: !!dreamId }
  )

  return { dreamImage, isLoadingDreamImage, isErrorDreamImage, dreamImageError }
}

export const usePostCreateDreamQuery = () => {
  const {
    mutateAsync: postCreateDream,
    isLoading: isLoadingPostCreateDream,
    isError: isErrorPostCreateDream,
    error: errorPostCreateDream
  } = useMutation('post-dream', postCreateDreamApi, {
    retry: 2,
    retryDelay: 3000
  })

  return {
    postCreateDream,
    isLoadingPostCreateDream,
    isErrorPostCreateDream,
    errorPostCreateDream
  }
}
