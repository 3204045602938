import { Navbar, NavItem as NavItemBase } from 'reactstrap'
import styled, { css } from 'styled-components'
import SkyLight from 'react-skylight'

export const Wrapper = styled.div<{ usePrimary?: boolean; open?: boolean; closeable?: boolean }>`
  .skylight-close-button {
    background: ${({ usePrimary, theme: { colors } }) =>
      usePrimary ? colors.primary : colors.secondary} !important;
    height: 40px !important;
    align-items: center;
    display: flex;
    font-size: 2.3rem !important;
    justify-content: center;
    padding: 0 !important;
    right: -20px !important;
    top: -20px !important;
    width: 40px !important;
  }

  .skylight-wrapper {
    display: none;
    height: 100vh;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;
  }

  ${({ open }) =>
    open &&
    css`
      position: relative;
      z-index: 9999;

      .skylight-wrapper {
        display: block;
      }
    `}

  .skylight-dialog {
    left: 6% !important;
    max-width: 100% !important;
    position: relative !important;
    right: 0 !important;
    top: 40px !important;
    height: auto !important;
    margin: auto !important;

    @media screen and (max-width: 768px) {
      top: 30px !important;
      left: 0 !important;
      width: 90% !important;
    }

    @media screen and (min-width: 1200px) {
      max-width: calc(100% - 250px - 18%) !important;
    }
  }

  &.xl-dialog {
    .skylight-dialog {
      max-width: initial !important;
    }
  }

  .skylight-close-button {
    display: ${({ closeable }) => (closeable ? 'flex' : 'none')};
  }
`

export const ModalHeader = styled.header`
  display: flex;
  flex-direction: column;
`

export const Navigation = styled(Navbar)`
  justify-content: flex-start;
  gap: 10px;
  padding: 0.5rem 1rem;
`

export const NavItem = styled(NavItemBase)`
  &.active {
    background: ${({ theme: { colors } }) => colors.secondary} !important;
    border-radius: 24px;
    pointer-events: none;
    color: #fff !important;

    > .button-border span {
      color: #fff !important;
      width: 100%;
    }
  }

  .button-border {
    border: 1px solid ${({ theme: { colors } }) => colors.secondary} !important;
    color: ${({ theme: { colors } }) => colors.primary} !important;
    border-radius: 24px;

    > div > div > div {
      color: ${({ theme: { colors } }) => colors.primary} !important;
    }
    span {
      color: ${({ theme: { colors } }) => colors.primary} !important;
      transition: all 0.2s ease-in-out;
      &:hover {
        color: ${({ theme: { colors } }) => colors.secondary} !important;
      }
    }
  }
`

export const InputText = styled.input`
  &[disabled] {
    opacity: 0.5;
  }
`

export const WrapperForModal = styled.div<{ usePrimary?: boolean; open?: boolean }>`
  .skylight-wrapper {
    display: none;
    height: 100vh;
    left: 0;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;
  }
  ${({ open }) =>
    open &&
    css`
      .skylight-wrapper {
        display: block;
      }
    `}

  .skylight-dialog {
    /* display: flex;
    flex-direction: column;
    height: fit-content;
    left: calc(250px + 6%) !important;
    margin: 1rem auto;
    max-width: calc(100% - 250px - 18%) !important;
    min-width: 450px;
    right: 0;
    top: 6% !important;
    position: fixed !important; */
    left: 6% !important;
    max-width: calc(100% - 250px - 18%) !important;
    position: relative !important;
    right: 0 !important;
    top: 40px !important;
    height: auto !important;
    margin: auto !important;
  }
`

export const StyledSkyLight = styled(SkyLight)`
  ${({ open }) =>
    open &&
    css`
      left: 0;
      top: 0;
      position: fixed;
      height: 100vh;
      padding: 1rem;
      width: 100vw;
      z-index: 999;
      overflow-y: auto;
    `}
`
