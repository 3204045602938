import styled from 'styled-components'
import { ModalV2 as Modal } from '../ModalV2'
import Button from '../../Buttons'
import colors from '../../../domain/Colors'

export const ModalV2 = styled(Modal)`
  .skylight-dialog {
    padding: 0 !important;
  }

  .skylight-close-button {
    z-index: 4;
  }
`

export const Header = styled.header`
  align-items: center;
  background: #fff;
  border-bottom: 2px solid #64646480;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  left: 0;
  padding: 0;
  padding: 1.2rem 1rem 1rem;
  position: sticky;
  top: 0;
  z-index: 2;
`

export const ButtonCreate = styled(Button)`
  background: ${({ theme }) => theme.colors.secondary};
  color: ${colors.white};
  font-weight: normal;

  &:focus,
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryDarker};
    color: white;
  }
`

export const Title = styled.h3`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 700;
`

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  color: #808080;
  font-weight: 700;
  font-size: 1.25rem;
  height: 100%;
  width: 100%;
`

export const Content = styled.div`
  min-height: 65vh;
  border-radius: 1rem 1rem 0 0;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const ListItem = styled.li`
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #dddddd;
  padding: 0.5rem 1rem;

  &:hover {
    border-bottom: 1px solid #dddddd;
    background: #fcfcfc;
  }
`

export const ListItemDate = styled.p`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;

  > span {
    align-items: center;
    display: flex;
    gap: 3px;
    font-weight: 400;
  }
`

export const ListItemButtons = styled.div`
  display: flex;
  gap: 5px;
`

export const IconButton = styled.button`
  border: none;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  padding: 0.3rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  height: 28px;
  width: 28px;

  i {
    color: #8ba2ba;
    font-size: 1.25rem;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${colors.lightGray2};
    i {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`
