import React from 'react'
import { useGetDreamImageByDreamId } from '../../../../../queries/plansDreams'
import * as S from './styles'
import { IPlansDreams } from '../../../../../domain/plansDreams/api'

export const DreamCard = ({ dream }: { dream: IPlansDreams }) => {
  const { dreamImage, isLoadingDreamImage } = useGetDreamImageByDreamId({ dreamId: dream.sonhoId })

  return (
    <S.Figure>
      <S.ImageContainer>
        {isLoadingDreamImage ? <S.Loader /> : <S.Image src={dreamImage} alt={dream.descricao} />}
      </S.ImageContainer>
      <S.FigureCaption>{dream.descricao}</S.FigureCaption>
    </S.Figure>
  )
}

export const DreamCardList = ({ dreams }: { dreams?: IPlansDreams[] }) => {
  return (
    <S.DreamCardList>
      {dreams?.map((dream) => (
        <DreamCard key={dream.sonhoId} dream={dream} />
      ))}
    </S.DreamCardList>
  )
}
