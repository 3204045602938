import { noticeError } from '../../../services/Monitoring'
import { postWithTokenApiV2, getWithToken } from '../../../webClient'
import { ManyResults, SingleResult } from '../../Results'

export interface IDreamRequest {
  familiaId: number
  empresaId: number
  descricao: string
  imagem: string
  tipo: 'sonho' | 'plano'
  categoriasonho: number
  statusSonho: number
}

export const postCreateDreamApi = async (body: IDreamRequest) => {
  const url = 'api/sonho'
  const { data, status } = await postWithTokenApiV2<{ exception?: { Message?: string } }, IDreamRequest>(
    url,
    body
  )

  if (status !== 200) {
    if (data?.exception?.Message) {
      const error = new Error(data.exception.Message)
      console.error(error)
      noticeError(error)
      throw error
    }
    const msg = 'Error creating dream'
    const error = new Error(msg)
    console.error(error)

    noticeError(error)
    throw new Error(msg)
  }

  return data
}

export interface IPlansDreams {
  bucketListId: number
  dataFinal: string
  dataInicial: string
  descricao: string
  ehAposentadoria: boolean
  empresaId: number
  familiaId: number
  imagem: string
  inflacao: number
  ordemPrioridade: unknown
  parcela: number
  percentualRealizadoFuturo: number
  percentualRealizadoPresente: number
  planoId: number
  prazo: number
  prazoRestante: number
  realizado: number
  sonhoId: number
  statusSonho: number
  taxa: number
  tipo: string
  valorEstimadoFuturo: number
  valorEstimadoPresente: number
}

export const getPlansDreamsByFamilyIdApi = async ({ familyId }: { familyId: number }) => {
  const url = `/sonho/planos/familia/${familyId}`

  const {
    data: { results: plansDreams },
    status
  } = await getWithToken<ManyResults<IPlansDreams>>(url)

  if (status !== 200) {
    const msg = `Error getting Plans and Dreams by familyId ${familyId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error, { familyId })
    throw new Error(msg)
  }

  return plansDreams
}

export const getDreamImageByDreamIdApi = async ({ dreamId }: { dreamId: number }) => {
  const url = `/sonho/imagem/${dreamId}`

  const {
    data: { singleResult: data },
    status
  } = await getWithToken<SingleResult<string>>(url)

  if (status !== 200) {
    const msg = `Error getting Image Dreams by dreamId ${dreamId}`
    const error = new Error(msg)
    console.error(error)
    noticeError(error, { dreamId })
    throw new Error(msg)
  }

  return data
}
