import React from 'react'
import * as S from '../styles'

const ICON_TYPES = {
  trash: 'far fa-trash-alt',
  download: 'fas fa-file-download',
  share: 'fas fa-share-square'
} as const

export const IconButton = ({ onClick, type }: { onClick: () => void; type: keyof typeof ICON_TYPES }) => {
  return (
    <S.IconButton onClick={onClick}>
      <i className={ICON_TYPES[type]} />
    </S.IconButton>
  )
}
